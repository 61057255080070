<template>
  <div class="mx-auto py-4 md:py-8">
    <div v-if="data?.title" class="mx-auto md:max-w-4xl lg:max-w-5xl">
      <h2 class="mb-6 px-5 text-center text-xl text-gray-900 md:text-2xl lg:text-4xl">
        {{ data.title }}
      </h2>
      <NuxtLink
        to="/fuer-unternehmen"
        rel="nofollow noopener"
        class="mb-4 flex justify-center p-2 transition hover:underline md:mb-8">
        <div class="flex items-center justify-center">
          <span class="mr-2">haelsi für Unternehmen</span>
          <SvgoArrowRight />
        </div>
      </NuxtLink>
    </div>
    <BlockCustomerLogosMarquee v-if="!isEmpty" :data="data?.customer_logos.data" />
  </div>
</template>

<script setup lang="ts">
import type { CustomerLogos } from "~/types/strapi/components/block/interfaces/CustomerLogos";

const props = defineProps<{
  data?: CustomerLogos;
}>();

const isEmpty = computed(() => props?.data?.customer_logos.data.length === 0);
</script>

<style scoped></style>
